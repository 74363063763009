<script lang="ts" setup>
const isSearch = ref(false)

const { mobile, platform } = useDisplay()

useMagicKeys({
  onEventFired(e) {
    if (((e.ctrlKey && !platform.value.mac) || (e.metaKey && platform.value.mac)) && e.key === 'k' && e.type === 'keydown') {
      e.preventDefault()
      isSearch.value = !isSearch.value
    }
  },
  passive: false,
})

// const { Ctrl_k } = useMagicKeys({
//   onEventFired(e) {
//     if (e.ctrlKey && e.key === 'k' && e.type === 'keydown') {
//       e.preventDefault()
//       isSearch.value = !isSearch.value
//     }
//   },
//   passive: false,
// })

// const keys = useMagicKeys()
// const CmdK = keys['Meta+K']

// watch(CmdK, (v) => {
//   if (v)
//     console.log('Meta + K has been pressed')
//   // do your own logic, maybe make dialog visible
// })

const leadStore = useLeadStore()

const links = ref([
  {
    color: 'primary',
    icon: 'tabler:home',
    panel: 'dashboard',
    subtitle: 'Dashboard',
    text: 'Mein Dashboard',
    to: '/dashboard',
  },
  {
    color: 'accent',
    icon: 'tabler:users-group',
    panel: 'leads',
    subtitle: 'Leads',
    text: 'Meine Leads',
    to: '/leads',
  },
  {
    color: 'secondary',
    icon: 'tabler:file-text',
    panel: 'offers',
    subtitle: 'Angebote',
    text: 'Meine Angebote',
    to: '/offers',
  },
  // {
  //   color: 'success',
  //   icon: 'tabler:user-check',
  //   panel: 'customers',
  //   subtitle: 'Kunden',
  //   text: 'Meine Kunden',
  //   to: '/customers',
  // },
])

// const gettingStarted = ref([
//   {
//     color: 'primary',
//     icon: 'tabler:file-text',
//     panel: 'dashboard',
//     subtitle: 'Dashboard',
//     text: 'Dashboard Einstellen',
//     to: '/dashboard',
//   },
//   {
//     color: 'accent',
//     icon: 'tabler:file-text',
//     panel: 'leads',
//     subtitle: 'Leads',
//     text: 'Leads bearbeiten',
//     to: '/leads',
//   },
//   {
//     color: 'secondary',
//     icon: 'tabler:file-text',
//     panel: 'offers',
//     subtitle: 'Angebote',
//     text: 'Angebote erstellen',
//     to: '/offers',
//   },
//   {
//     color: 'success',
//     icon: 'tabler:file-text',
//     panel: 'customers',
//     subtitle: 'Kunden',
//     text: 'Kunden bearbeiten',
//     to: '/customers',
//   },
// ])

function handleRedirect(to: string) {
  isSearch.value = false
  navigateTo(to)
}

const search = ref('')
const searchItems = ref<Lead[]>([])

watchDebounced(
  search,
  async () => {
    if (search.value.length >= 3) {
      const response = await $fetch<{ documents: Lead[], total: number }>(`/api/v1/leads`, {
        baseURL: useRuntimeConfig().public.base.url,
        params: {
          query: [
            Query.limit(10),
            Query.orderDesc('bookedAt'),
            Query.select([
              '$id',
              'bookedAt',
              'birthday',
              'professionalStatus',
              'firstName',
              'lastName',
              'address',
              'email',
              'phone',
              'phone2',
              'postalCode',
              'city',
              'type',
              'avatarId',
              'state',
              'complaint',
            ]),
            Query.or([
              Query.contains('firstName', search.value),
              Query.contains('lastName', search.value),
              Query.contains('address', search.value),
              Query.contains('email', search.value),
              Query.contains('phone', search.value),
              Query.contains('phone2', search.value),
              Query.contains('city', search.value),
              Query.contains('postalCode', search.value),
              Query.contains('type', search.value),
              Query.contains('state', search.value),
              Query.contains('complaint', search.value),
              // Query.contains('birthday', search.value),
              Query.contains('professionalStatus', search.value),
            ]),
          ],
        },
      })
      searchItems.value = response.documents
    }
    else {
      searchItems.value = []
    }
  },
  { debounce: 500, maxWait: 1000 },
)
</script>

<template>
  <div>
    <v-dialog
      v-model="isSearch" width="500" transition="dialog-top-transition" opacity="0.75" scrim="surface"
    >
      <v-card variant="flat">
        <v-toolbar class="d-flex align-center justify-space-between" color="surface-light" density="compact">
          <v-text-field
            v-model="search"
            autofocus
            class="elevation-0"
            bg-color="surface-light"
            hide-details
            placeholder="Suche..."
            type="text"
            variant="filled"
          >
            <template #prepend-inner>
              <Icon name="tabler:search" size="24" />
            </template>
          </v-text-field>
          <template #append>
            <v-btn aria-label="Close Chat" class="ml-2" size="40" variant="text" @click="isSearch = false">
              <Icon name="tabler:x" size="18" />
            </v-btn>
          </template>
        </v-toolbar>
        <v-row no-gutters class="overflow-y-auto">
          <v-fade-transition>
            <v-col v-if="searchItems.length > 0" cols="12">
              <v-list
                class="px-4"
                density="compact"
              >
                <v-list-subheader>Suchergebnisse</v-list-subheader>

                <v-list-item
                  v-for="(item, index) in searchItems"
                  :key="item.$id"
                  v-auto-animate
                  class="pa-1 mb-2 text-caption rounded-xl px-2"
                  :class="[
                    index % 2 === 0 ? 'odd' : 'even',
                  ]"
                  color="primary"
                  two-line
                  @click="handleRedirect(`/leads/${item.$id}`)"
                >
                  <template #prepend>
                    <v-badge
                      dot :color="useColor().determineStatusColor(item.state)"
                      @click.stop="handleRedirect(item.$id)"
                    >
                      <v-avatar
                        class="info-icon"
                        color="surface-light"
                        size="40"
                      >
                        <v-img
                          v-if="item.avatarId"
                          class="rounded-pill avatar"
                          alt="Avatar"
                          :src="`${useAppwrite().imagePreview('leadAvatars', item.avatarId)}?width=40&height=40&output=webp`"
                        />
                        <span v-else-if="item.lastName" class="avatar text-body-1 font-weight-thin">
                          {{ useString().getInitials(`${item.firstName} ${item.lastName}`) }}
                        </span>
                        <Icon class="info-icon" name="tabler:external-link" size="18" />
                      </v-avatar>
                    </v-badge>
                    <v-tooltip
                      activator="parent"
                      content-class="elevation-0 rounded-xl text-body-1"
                      location="bottom"
                      text="Detailseite anzeigen"
                    />
                  </template>
                  <div class="d-block text-truncate">
                    {{ item.firstName }} {{ item.lastName }}, {{ item.city }}
                  </div>
                  <div
                    class="d-block text-truncate"
                  >
                    {{ item.type }} {{ item.state }}
                  </div>
                  <template #append>
                    <AppQrPopover
                      :copy-text="item.phone"
                      :link="`tel:${item.phone}`"
                      title="Anrufen"
                      :button-props="{
                        color: 'success',
                        icon: true,
                        size: 'small',
                        variant: 'text',
                      }"
                      :menu-props="{ openOnHover: true, location: 'top' }"
                      :size="120"
                      @click.stop
                    >
                      <Icon
                        name="tabler:phone"
                        size="24"
                      />
                    </AppQrPopover>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>
          </v-fade-transition>
          <v-col cols="12">
            <v-list
              class="px-4"
              density="compact"
            >
              <v-list-subheader>Links</v-list-subheader>

              <v-list-item
                v-for="(item) in links"
                :key="item.to"
                class="pa-1 mb-2 text-caption rounded-xl px-2"
                :class="[
                  index % 2 === 0 ? 'odd' : 'even',
                ]"
                color="primary"
                two-line
                :title="item.text"
                @click="handleRedirect(item.to)"
              >
                <template #prepend>
                  <Icon class="mr-4" :name="item.icon" size="20" />
                </template>
              </v-list-item>
            </v-list>
          </v-col>
          <!-- <v-col cols="12">
            <v-list density="compact">
              <v-list-subheader>Erste Schritte</v-list-subheader>

              <v-list-item
                v-for="(item) in gettingStarted"
                :key="item.to"
                disabled
                :active="false"
                :value="item"
                color="primary"
                :to="item.to"
                :title="item.text"
              >
                <template #prepend>
                  <Icon class="mr-4" :name="item.icon" size="20" />
                </template>
              </v-list-item>
            </v-list>
          </v-col> -->
        </v-row>
      </v-card>
    </v-dialog>
    <v-btn
      class="rounded-pill d-flex align-center justify-lg-space-between ga-2"
      color="surface-variant"
      variant="flat"
      height="40"
      @click="isSearch = true"
    >
      <Icon name="tabler:search" size="18" />
      <span v-if="!mobile" class="mx-2 pa-0">Suche</span>
      <v-chip v-if="!mobile" variant="outlined" size="small">
        <Icon v-if="platform.mac" name="tabler:command" size="18" />
        <span v-else>Strg</span> + K
      </v-chip>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.v-list-item {
  &.odd {
    background-color: rgba(var(--v-theme-surface-light), 0.1);
  }

  &.even {
    background-color: rgba(var(--v-theme-surface-bride), 0.1);
  }
  &:hover {
    background: rgba(var(--v-theme-surface), var(--v-theme-overlay-multiplier));
  }
}
</style>
