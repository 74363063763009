<script lang="ts" setup>
const mainNav = ref([
  {
    color: 'primary',
    icon: 'tabler:home',
    panel: 'dashboard',
    subtitle: 'Dashboard',
    text: 'Mein Dashboard',
    to: '/dashboard',
  },
  {
    color: 'accent',
    icon: 'tabler:users-group',
    panel: 'leads',
    subtitle: 'Leads',
    text: 'Meine Leads',
    to: '/leads',
  },
  {
    color: 'secondary',
    icon: 'tabler:file-text',
    panel: 'offers',
    subtitle: 'Angebote',
    text: 'Meine Angebote',
    to: '/offers',
  },
  // {
  //   color: 'success',
  //   icon: 'tabler:user-check',
  //   panel: 'customers',
  //   subtitle: 'Kunden',
  //   text: 'Meine Kunden',
  //   to: '/customers',
  // },
])
</script>

<template>
  <v-app-bar
    elevation="0"
    rounded="0"
    height="104"
  >
    <v-container class="py-0 fill-height h-100 px-8" style="max-inline-size: 1440px">
      <v-row class="px-6">
        <v-col class="d-flex align-center ga-4" cols="12" md="6">
          <v-btn
            v-for="item in mainNav"
            :key="item.to"
            :aria-label="item.subtitle"
            class="rounded-0"
            :class="[{
              active: $route.fullPath.includes(item.to),
            }, item.color]"
            icon
            size="small"
            variant="plain"
            :to="item.to"
          >
            <Icon :name="item.icon" size="24" />
            <v-tooltip
              activator="parent"
              content-class="rounded-xl text-body-1"
              location="bottom"
              :text="item.text"
            />
          </v-btn>
        </v-col>
        <v-col class="d-flex align-center ga-4" cols="12" md="6">
          <UiGlobalSearch />
        </v-col>
      </v-row>
    </v-container>

    <template #append>
      <v-card
        class="pl-6 pr-1 d-flex align-center justify-space-between ga-4"
        color="transparent"
        variant="flat"
      >
        <UiColorModeButton class="ml-auto" />
        <AccountUser />
      </v-card>
    </template>
  </v-app-bar>
</template>

<style lang="scss" scoped>
.sticky {
  // z-index: 1000;
  transition: all 0.2s ease-in-out;
  backdrop-filter: blur(6px);
  background: transparent !important;
  // background-color: rgb(var(--v-theme-background), 0);
}

:deep() {
  .v-btn {
    &.v-btn--icon {
      .v-btn__content {
        padding-bottom: 5px;
      }
    }

    &.active {
      opacity: 1;

      &.accent {
        .v-btn__content {
          box-shadow: rgba(var(--v-theme-accent), 1) 0px 2px;
        }
      }
      &.primary {
        .v-btn__content {
          box-shadow: rgba(var(--v-theme-primary), 1) 0px 2px;
        }
      }
      &.secondary {
        .v-btn__content {
          box-shadow: rgba(var(--v-theme-secondary), 1) 0px 2px;
        }
      }
      &.success {
        .v-btn__content {
          box-shadow: rgba(var(--v-theme-success), 1) 0px 2px;
        }
      }
      .v-btn__content {
        box-shadow: rgba(var(--v-theme-primary), 1) 0px 2px;
      }
    }
  }
  .v-toolbar__prepend {
    margin-inline: 22px;
  }
  .v-toolbar__append {
    margin-inline: auto 0;
  }
}
</style>
