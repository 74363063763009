<script setup lang="ts">
const accountStore = useAccountStore()
const {
  lastUpdated,
} = storeToRefs(accountStore)

const { data: account, execute, pending } = useLazyAsyncData(
  'account',
  () => $fetch(`/api/v1/account`, {
    baseURL: useRuntimeConfig().public.base.url,
  }),
  {
    immediate: false,
    watch: [
      lastUpdated,
    ],
  },
)
onMounted(() => {
  execute()
})
</script>

<template>
  <v-menu
    content-class="elevation-0" offset-y offset="0"
  >
    <template #activator="{ isActive, props: menuProps }">
      <v-btn
        icon size="44"
        v-bind="menuProps"
      >
        <v-avatar
          color="surface-light"
          size="44"
        >
          <v-avatar
            size="40"
          >
            <Icon
              v-if="pending"
              class="text-primary" name="svg-spinners:pulse-multiple" size="40"
            />
            <v-img
              v-else-if="account?.prefs?.avatarId"
              :alt="useString().getInitials(account?.name)"
              :src="`${useAppwrite().imagePreview('userAvatars', account?.prefs?.avatarId)}?width=40&height=40&output=webp`"
            >
              <template #placeholder>
                <Icon
                  v-if="pending"
                  class="text-primary" name="svg-spinners:pulse-multiple" size="40"
                />
              </template>
            </v-img>
            <Icon
              v-else
              name="tabler:user"
              size="24"
            />
          </v-avatar>
        </v-avatar>
        <v-tooltip
          v-if="!isActive"
          activator="parent"
          content-class="bg-surface elevation-0 rounded-xl text-body-1"
          location="bottom"
          text="Mein Account"
        />
      </v-btn>
    </template>
    <v-list>
      <v-list-item :title="account?.email" />
      <v-divider class="mb-2" />
      <v-list-item :active="false" :title="$t('navigation.top.account')" to="/account" />
      <!-- <v-list-item :active="false" :title="$t('navigation.top.settings')" to="/settings" /> -->
      <AccountSignout />
      <!-- <AccountSwitch /> -->
    </v-list>
  </v-menu>
</template>
