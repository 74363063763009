<script setup lang="ts">
const notificationStore = useNotificationStore()
const { notifications } = storeToRefs(notificationStore)

const notificationsShown = computed(() =>
  notifications.value.filter(notification => notification.show).reverse(),
)
const showAll = ref(false)
const timeout = ref(5000)
function deleteNotification(id: number) {
  notificationStore.delNotification(id)
}
function emptyNotifications() {
  notificationStore.$reset()
}
function toggleAll() {
  showAll.value = !showAll.value
  notifications.value.forEach((m) => {
    m.show = showAll.value
  })
  if (showAll.value)
    timeout.value = -1

  else
    timeout.value = 5000
}
</script>

<template>
  <v-btn
    :color="showAll ? 'primary' : 'on-background'"
    icon
    size="small"
    variant="text"
    @click="toggleAll"
  >
    <v-badge
      :content="notifications.length > 0 ? notifications.length : undefined"
      :color="notifications.length > 0 ? 'error' : 'transparent'"
      max="9"
    >
      <Icon
        name="tabler:bell"
        size="24"
      />
    </v-badge>

    <v-tooltip
      v-if="!showAll"
      activator="parent"
      content-class="bg-surface elevation-0 rounded-xl text-body-1"
      location="bottom"
      text="Meine Benachrichtigungen"
    />
  </v-btn>
  <teleport to="body">
    <v-card
      :width="$vuetify.display.width / 5"
      class="d-flex flex-column notification-card rounded-s-0 rounded-e-xl"
      :class="{ 'notification-card--open': showAll }"
      variant="flat"
    >
      <v-toolbar v-if="showAll" flat density="compact">
        <v-toolbar-title
          class="font-weight-light text-body-1"
          :text="notifications.length ? 'Benachrichtigungen' : 'Keine neuen Benachrichtigungen'"
        />
        <v-btn
          icon
          size="x-small"
          variant="text"
          title="Clear All Notifications"
          @click="emptyNotifications"
        >
          <Icon
            name="tabler:bell-x"
            size="18"
          />
        </v-btn>
      </v-toolbar>
      <v-scale-transition
        tag="div"
        class="d-flex flex-column notification-box"
        group
        hide-on-leave
      >
        <div
          v-for="notification in notificationsShown"
          :key="notification.id"
          class="notification-item-wrapper"
        >
          <AppNotificationItem
            v-model="notification.show"
            :variant="showAll"
            :notification="notification"
            :timeout="timeout"
            class="notification-item"
            @close="deleteNotification(notification.id)"
          />
        </div>
      </v-scale-transition>
    </v-card>
  </teleport>
</template>

<style scoped>
.notification-item {
  width: 100%;
  border: 0;
}
.notification-card {
  z-index: 10001;
  position: fixed;
  left: 16px;
  top: 104px;
  max-height: calc(100dvh - 104px);
  overflow: visible;
  visibility: hidden;
  &.notification-card--open {
    left: 268px;
    top: 8px;
    visibility: visible;
    overflow: hidden;
    max-height: calc(100dvh - 116px);
    .notification-box {
      overflow-y: overlay;
      pointer-events: auto;
      .notification-item-wrapper {
        transition: none !important;
        .notification-item {
          margin-bottom: 0;
          border-radius: 0;
          border-top: 1px solid #5656563d !important;
        }
      }
    }
  }
}
.notification-box {
  overflow-y: visible;
  visibility: visible;
  pointer-events: none;
  .notification-item {
    pointer-events: initial;
    user-select: initial;
    margin-bottom: 10px;
  }
}
</style>
