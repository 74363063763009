<script lang="ts" setup>
const theme = useTheme()

const appSettings = useAppSettingsStore()
const { settings } = storeToRefs(appSettings)

function toggleTheme() {
  settings.value.appearance.darkMode = !settings.value.appearance.darkMode
  theme.global.name.value = settings.value.appearance.darkMode ? 'dark' : 'light'
}
</script>

<template>
  <v-btn
    aria-label="Theme"
    icon
    size="small"
    variant="text"
    @click="toggleTheme"
  >
    <Icon
      v-if="settings?.appearance?.darkMode"
      name="tabler:moon"
      size="24"
    />
    <Icon
      v-else
      name="tabler:sun"
      size="24"
    />
    <v-tooltip
      activator="parent"
      content-class="bg-surface elevation-0 rounded-xl text-body-1"
      location="bottom"
      text="Design ändern"
    />
  </v-btn>
</template>
